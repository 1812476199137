export const countries = [
  {
    name: "English",
    code: "english",
    flag: "https://flagcdn.com/16x12/gb.png",
  },
  { name: "Arabic", code: "arabic", flag: "https://flagcdn.com/16x12/sa.png" },
  { name: "French", code: "french", flag: "https://flagcdn.com/16x12/fr.png" },
  {
    name: "Deutsch",
    code: "deutsch",
    flag: "https://flagcdn.com/16x12/de.png",
  },
  {
    name: "Italian",
    code: "italian",
    flag: "https://flagcdn.com/16x12/it.png",
  },
  {
    name: "Spanish",
    code: "spanish",
    flag: "https://flagcdn.com/16x12/es.png",
  },
  {
    name: "Portuguese",
    code: "portuguese",
    flag: "https://flagcdn.com/16x12/pt.png",
  },
  {
    name: "Russian",
    code: "russian",
    flag: "https://flagcdn.com/16x12/ru.png",
  },
  {
    name: "Chinese",
    code: "chinese",
    flag: "https://flagcdn.com/16x12/cn.png",
  },
];

export const languageMap = {
  "en": "English",
  "en-GB": "English",
  "en-US": "English",
  "it-IT": "Italian",
  "it": "Italian",
  "ru-RU": "Russian",
  "ru": "Russian",
  "cn-CN": "Chinese",
  "zh-CN": "Chinese",
  "cn": "Chinese",
  "zh": "Chinese",
  "pt-PT": "Portuguese",
  "pt": "Portuguese",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "es-ES": "Spanish",
  "es": "Spanish",
  "ar-AR": "Arabic",
  "ar": "Arabic",
  "fr-FR": "French",
  "fr": "French",
};

export const data = [
  {
    id: 1,
    icone: "fa-comments",
    title: "GeneralWilling",
    text: "Ask us anything you need, about any topic and our last generation AI will answer you.",
    link: "/general-knowledge",
  },
  {
    id: 2,
    icone: "fa-pen-to-square",
    title: "BooksResume",
    text: "Providing book summaries using AI technology, helping students to quickly understand and comprehend reading material. You can also write any text and we will summarise it for you.",
    link: "/summary",
  },
  {
    id: 3,
    icone: "fa-atom",
    title: "Science",
    text: "We assists with science homework assignments, using AI technology to provide students with detailed answers and explanations in various scientific fields.",
    link: "/science",
  },
  {
    id: 4,
    icone: "fa-palette",
    title: "Art",
    text: "We offer support for art-related homework assignments through our AI technology, helping students to enhance their understanding of artistic concepts and techniques.",
    link: "/art",
  },
  {
    id: 5,
    icone: "fa-book-open-reader",
    title: "Literature",
    text: "We offer support for literature-related homework assignments, using AI technology to offer students in-depth analysis and understanding of various literary works.",
    link: "/literature",
  },
  {
    id: 6,
    icone: "fa-file-lines",
    title: "Math",
    text: "We offer support for math homework assignments, utilizing AI technology to provide students with efficient and accurate solutions.",
    link: "/math",
  },
  {
    id: 7,
    icone: "fa-clock-rotate-left",
    title: "History",
    text: "We assists with history homework assignments through AI technology, providing students with accurate and comprehensive information about historical events and figures.",
    link: "/history",
  },
  {
    id: 8,
    icone: "fa-flask-vial",
    title: "Physics",
    text: "We offer support for physics-related homework assignments, utilizing AI technology to provide students with detailed solutions and explanations in the field of physics.",
    link: "/physics",
  },
  {
    id: 9,
    icone: "fa-bookmark",
    title: "Essay",
    text: "Our innovative AI, can generate you an essay about any topic you need. It can also give you ideas about your future essay or about an thesis that you need for school or university.",
    link: "/essay-thesis",
  },
];

export const blogs = [
  {
    id: 1,
    title: "Last generation technology",
    text: "Technology is constantly evolving, with new innovations emerging every year that change ...",
    link: "/last-generation-technology",
  },
  {
    id: 2,
    title: "Artificial Intelligence (AI)",
    text: "Artificial Intelligence (AI) is a field of computer science that focuses on developing intelligent machines ...",
    link: "/artificial-intelligence",
  },
  {
    id: 3,
    title: "Quantum computing",
    text: "Quantum computing is a relatively new field of study that has the potential to revolutionize the way we ...",
    link: "/quantum-computing",
  },
  {
    id: 4,
    title: "Blockchain",
    text: "Blockchain is a revolutionary technology that has the potential to transform the way we store and transfer data ...",
    link: "/blockchain",
  },
  {
    id: 5,
    title: "5G",
    text: "5G is the fifth generation of wireless technology, offering faster speeds and increased connectivity than ...",
    link: "/5g",
  },
  {
    id: 6,
    title: "Virtual reality (VR)",
    text: "Virtual reality (VR) is a technology that has seen significant growth in recent years, offering immersive experiences ...",
    link: "/virtual-reality",
  },
  {
    id: 7,
    title: "Robots and Automation",
    text: "5G is the fifth generation of wireless technology, offering faster speeds and increased connectivity than ...",
    link: "/robots-and-automation",
  },
  {
    id: 8,
    title: "Cybersecurity",
    text: "Cybersecurity is a critical aspect of modern technology, as the internet and connected devices have become ...",
    link: "/cybersecurity",
  },
  {
    id: 9,
    title: "Nanotechnology",
    text: "Nanotechnology and microelectronics are two fields of technology that are revolutionizing the ...",
    link: "/nanotechnology",
  },
  {
    id: 10,
    title: "Autonomous vehicles",
    text: "Autonomous vehicles and drones are two technologies that have seen rapid growth in recent years ...",
    link: "/autonomous-vehicles",
  },
  {
    id: 11,
    title: "Augmented Reality (AR)",
    text: "Augmented Reality (AR) is a rapidly growing field of technology that overlays digital information ...",
    link: "/augmented-reality",
  },
  {
    id: 12,
    title: "Biotechnology",
    text: "Biotechnology is the application of biological processes and techniques in the development of ...",
    link: "/biotechnology",
  },
  {
    id: 13,
    title: "Smart homes and cities",
    text: "Smart homes and cities are revolutionizing the way we live and work by incorporating the latest ...",
    link: "/smart-homes-and-cities",
  },
  {
    id: 14,
    title: "Renewable energy technologies",
    text: "Renewable energy technologies, such as solar, wind, and hydropower, are becoming increasingly ...",
    link: "/renewable-energy-technologies",
  },
  {
    id: 15,
    title: "The Internet of Things (IoT)",
    text: "The Internet of Things (IoT) is a concept that refers to the proliferation of connected devices ...",
    link: "/the-internet-of-things",
  },
  {
    id: 16,
    title: "VIRTUAL REALITY HEADSET",
    text: "Virtual Reality (VR) headsets are a new type of technology that allows users to enter immersive ...",
    link: "/virtual-reality-headset",
  },
  {
    id: 17,
    title: "3D Printing",
    text: "3D printing, also known as additive manufacturing, is a revolutionary technology that ...",
    link: "/3d-printing",
  },
  {
    id: 18,
    title: "Drones",
    text: "Drones, also known as unmanned aerial vehicles (UAVs), have become increasingly popular ...",
    link: "/drones",
  },
  {
    id: 19,
    title: "Smart Glasses",
    text: "Smart glasses are a wearable technology that combines eyeglasses with computer technology ...",
    link: "/smart-glasses",
  },
  {
    id: 20,
    title: "Smartwatches",
    text: "Smartwatches have become increasingly popular in recent years, as people look for new and convenient ...",
    link: "/smart-watches",
  },
];
